import { createSlice } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

interface InitialState {
    selectedTabKey: string;
    selectedReconciliation: any;
    selectedReconciliationDate: Dayjs | undefined;
    showReconciliationDatePicker: boolean;
}

let initialState: InitialState = {
    selectedTabKey: "1",
    selectedReconciliationDate: undefined,
    selectedReconciliation: null,
    showReconciliationDatePicker: true
}

export const reconciliationSlice = createSlice({
    name: "reconciliation",
    initialState,
    reducers: {
        changeTabKey(state, action) {
            state.selectedTabKey = action.payload;
        },
        selectReconciliation(state, action) {
            state.selectedReconciliation = action.payload;
        },
        selectReconciliationDate(state, action) {
            state.selectedReconciliationDate = action.payload;
        },
        toggleReconciliationDatePickerDisplay(state) {
            state.showReconciliationDatePicker = !state.showReconciliationDatePicker
        }
    }
})

export const reconciliationReducers = reconciliationSlice.actions