import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const reconciliationsApi = SplitApiV2.injectEndpoints(
    {
        endpoints: (builder) => ({
            getReconciliations: builder.query<any, any>({
                query: (date) => urlPrepare(`/`)
            }),
            getClientsHolding: builder.query<any, any>({
                query: (date) => urlPrepare(`/reports/holdings/per-ticker?group_by_user=true&positions_at=${date}`)
            }),
            getSecuritiesHeld: builder.query<any, any>({
                query: (date) => urlPrepare(`/`)
            }),
            requestReconciliation: builder.mutation<any, any>({
                query: ({ id, body }) => ({
                    url: urlPrepare(`/`),
                    method: "POST",
                    body
                }),
            }),
        })
    }
)

export const {
    useGetReconciliationsQuery,
    useGetClientsHoldingQuery,
    useGetSecuritiesHeldQuery,
    useRequestReconciliationMutation
} = reconciliationsApi